import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Seo from '../components/seo';
import '../styles/main.scss';

import Navigation from '../components/Navigation';
import WebsiteFooter from '../components/Footer';

import PageHeader from '../components/PageHeader';

const ContactIntro = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 3em;
  margin-bottom: 3em;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .first {
    flex-basis: 45%;
    @media (max-width: 768px) {
      margin-bottom: 2em;
    }
  }
  .second {
    flex-basis: 45%;
    width: 100%;
    img {
      border-radius: 4px;
    }
  }
`;

const ContactInformation = styled.section`
  background: #f7faf8;
  padding: 3em 0;
`;

const ContactInformationInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .second {
    flex-basis: 45%;
    @media (max-width: 768px) {
      flex-basis: 100%;
    }
    display: flex;
    flex-direction: column;
    background: #e1eff6;
    padding: 2em 1em;
    * {
      margin-bottom: 1em;
    }
    button {
      background: #c25803;
      border: unset;
      padding: 1em;
      color: white;
      transition: 0.3s;
      margin-bottom: 0;
      &:hover {
        background: black;
        color: white;
      }
    }
  }
`;

const ServicesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      headerImage: file(relativePath: { eq: "services-header.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      contactImage: file(relativePath: { eq: "contact-image.jpg" }) {
        childImageSharp {
          fluid(quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <>
      <Seo title="Contact" />
      <Navigation showLogo background />
      <PageHeader title="Contact" data={data} />
      <ContactIntro className="container">
        <div className="first">
          <p>
            We know the online world can be daunting, but we have helped antique
            dealers grow their online turnover into millions. We are available
            to offer advice, support and results to the trade. Please get in
            contact to find out further information.
          </p>
        </div>
        <div className="second">
          <Img
            fluid={data.contactImage.childImageSharp.fluid}
            alt="Contact"
            style={{ height: '500px' }}
          />
        </div>
      </ContactIntro>
      <ContactInformation>
        <div className="container container__extra_small">
          <ContactInformationInner>
            <form
              action="https://getform.io/f/c3c2ee62-1982-47fe-9909-5a17bbac5fba"
              method="POST"
              className="second"
            >
              <input placeholder="Name" required type="text" name="name" />
              <input placeholder="Phone Number" type="tel" name="phone" />
              <input placeholder="Email Address" type="email" name="email" />
              <textarea placeholder="Message" name="message" />
              <div class="g-recaptcha" data-sitekey="6LcGhLYgAAAAALjD1FlNuhcyp8tSg0jGf2nfahyp" />
              <button type="submit">Submit</button>
            </form>
          </ContactInformationInner>
        </div>
      </ContactInformation>
      <WebsiteFooter />
    </>
  );
};

export default ServicesPage;
