import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

const BackgroundSection = ({ title, image }) => (
  <>
    <BackgroundImage Tag="div" fluid={image} backgroundColor="#c25803">
      <Overlay />
      <HeaderTitle>{title}</HeaderTitle>
    </BackgroundImage>
  </>
);

const HeaderContainer = styled(BackgroundSection)`
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
`;

const HeaderTitle = styled.h1`
  position: relative;
  text-align: center;
  z-index: 2;
  font-weight: 800;
  color: white;
  padding: 2em 0;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 19, 54, 0.4);
  top: 0;
  left: 0;
  z-index: 1;
`;

const PageHeader = ({ title, data }) => {
  const imageData = data.headerImage.childImageSharp.fluid;
  return (
    <>
      <HeaderContainer image={imageData} title={title} />
    </>
  );
};

export default PageHeader;

PageHeader.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
};

BackgroundSection.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object,
};
